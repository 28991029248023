import { render, staticRenderFns } from "./NewGroup.vue?vue&type=template&id=5f8d8f38"
import script from "./NewGroup.vue?vue&type=script&lang=js"
export * from "./NewGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports