<template>
  <aside>
    <header>
      <h2>Add a Channel</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Slack channel</h4>
        </div>
        <div class="fields">
          <channel-selector v-model="$v.channel.$model" :class="{'is-invalid': $v.channel.$error}" />
          <div class="invalid-feedback">A channel is required.</div>
        </div>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="addChannel">Add channel</button>
    </footer>
  </aside>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      channel: null
    }
  },
  watch: {

  },
  validations: {
    channel: {
      required
    }
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async addChannel() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.$store.commit('loading', true)

      const resp = await this.$api.Groups.create({
        title: `#${this.channel.name}`,
        channel_id: this.channel.id
      })

      if(resp.error) {
        this.$store.commit('loading', false)
        this.$toast.error(resp.message)
        return;
      }

      await this.$store.dispatch('populateGroups')
      this.$store.dispatch('teamMetadata', {setup_first_group: true});

      this.$store.commit('loading', false)

      this.$router.push({
        name: 'recognition-group-edit',
        params: {
          group_id: resp.id
        }
      })

      this.$emit('done', 'CREATED_GROUP')
    }
  }
}
</script>